"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const ng = window.angular;
class BrasizecalculatorCtrl {
    constructor($scope, $timeout, ModalServiceFactory, ConfigService, $filter) {
        this.$scope = $scope;
        this.$timeout = $timeout;
        this.ModalServiceFactory = ModalServiceFactory;
        this.ConfigService = ConfigService;
        this.$filter = $filter;
        this.unit = "metric";
        this.hasError = false;
        this.data = {
        //bust: 92,
        //band: 80
        };
        this.tableHeads = [
            {
                index: 1,
                regions: [
                    {
                        name: this.$filter('translate')('International'),
                        code: 'INT'
                    }, {
                        name: this.$filter('translate')('Europe'),
                        code: 'EU'
                    }
                ]
            },
            {
                index: 2,
                regions: [
                    {
                        name: this.$filter('translate')('USA'),
                        code: 'US'
                    }, {
                        name: this.$filter('translate')('Canada'),
                        code: 'CA'
                    }
                ]
            },
            {
                index: 3,
                regions: [
                    {
                        name: this.$filter('translate')('United Kingdom'),
                        code: 'UK'
                    }
                ]
            },
            {
                index: 4,
                regions: [
                    {
                        name: this.$filter('translate')('France'),
                        code: 'FR'
                    }, {
                        name: this.$filter('translate')('Spain'),
                        code: 'ES'
                    }, {
                        name: this.$filter('translate')('Belgium'),
                        code: 'BE'
                    },
                ]
            },
            {
                index: 5,
                regions: [
                    {
                        name: this.$filter('translate')('Italy'),
                        code: 'IT'
                    }
                ]
            },
            {
                index: 6,
                regions: [
                    {
                        name: this.$filter('translate')('Australia'),
                        code: 'AU'
                    }, {
                        name: this.$filter('translate')('New Zealand'),
                        code: 'NZ'
                    }
                ]
            },
            {
                index: 7,
                regions: [
                    {
                        name: this.$filter('translate')('Japan'),
                        code: 'JP'
                    }, {
                        name: this.$filter('translate')('South Korea'),
                        code: 'KO'
                    }
                ]
            },
        ];
        this.cupTable = {
            metric: [
                [[0, 12], 'AA', 'A', 'A', 'AA', 'AA', 'AA', 'A'],
                [[12, 14], 'A', 'B', 'B', 'A', 'A', 'A', 'B'],
                [[14, 16], 'B', 'B/C', 'B/C', 'B', 'B', 'B', 'B/C'],
                [[16, 18], 'C', 'C', 'C', 'C', 'C', 'C', 'C'],
                [[18, 20], 'D', 'D', 'D', 'D', 'D', 'D', 'D'],
                [[20, 22], 'E', 'DD/E', 'DD', 'E', 'E', 'DD', 'E'],
                [[22, 24], 'F', 'DDD/F', 'E', 'F', 'F', 'E', 'F'],
                [[24, 26], 'G', 'DDD/F/DDDD/G', 'E/F', 'G', 'G', 'F', 'F/G'],
                [[26, 28], 'H', 'DDDD/G', 'F', 'H', 'H', 'G', 'G'],
                [[28, 30], 'I', 'H', 'FF', 'I', 'I', 'H', 'H'],
                [[30, 32], 'J', 'I', 'G', 'J', 'J', 'I', 'I'],
                [[32, 34], 'K', 'J', 'GG', 'K', 'K', 'J', 'J'],
                [[34, 36], 'L', 'J/K', 'GG/H', 'L', 'L', 'K', 'J/K'],
                [[36, 38], 'M', 'K', 'H', 'M', 'M', 'L', 'K'],
                [[38, 40], 'N', 'L', 'HH', 'N', 'N', 'M', 'L'],
                [[40, 42], 'O', 'M', 'J', 'O', 'O', 'N', 'M'],
                [[42, 44], 'P', 'N', 'JJ', 'P', 'P', 'O', 'N'],
                [[44, 46], 'Q', 'N/O', 'JJ/K', 'R', 'R', 'P', 'N/O'],
                [[46, 48], 'R', 'O', 'K', 'S', 'S', 'R', 'O'],
                [[48, 50], 'S', 'P', 'KK', 'T', 'T', 'S', 'P'],
                [[50, 52], 'T', '—', '—', '—', '—', '—', '—'],
                [[52, 54], 'U', '—', '—', '—', '—', '—', '—'],
                [[54, 56], 'V', '—', '—', '—', '—', '—', '—'],
                [[56, 58], 'W', '—', '—', '—', '—', '—', '—'],
                [[58, 60], 'X', '—', '—', '—', '—', '—', '—'],
                [[60, 62], 'Y', '—', '—', '—', '—', '—', '—'],
                [[62, 64], 'Z', '—', '—', '—', '—', '—', '—'],
            ],
            imperial: [
                [[0, 4], 'AA', 'AA', 'AA', 'AA', 'AA', 'AA', 'AA'],
                [[4, 5], 'AA', 'A', 'A', 'AA', 'AA', 'AA', 'A'],
                [[5, 6], 'A/B', 'B', 'B', 'A/B', 'A/B', 'A/B', 'B'],
                [[6, 7], 'C', 'C', 'C', 'C', 'C', 'C', 'C'],
                [[7, 8], 'D', 'D', 'D', 'D', 'D', 'D', 'D'],
                [[8, 9], 'E/F', 'DD/E', 'DD', 'E/F', 'E/F', 'DD/E', 'E'],
                [[9, 10], 'F/G', 'DDD/F', 'E', 'F/G', 'F/G', 'E/F', 'F'],
                [[10, 11], 'H', 'DDDD/G', 'F', 'H', 'H', 'G', 'G'],
                [[11, 12], 'I', 'H', 'FF', 'I', 'I', 'H', 'H'],
                [[12, 13], 'J/K', 'I', 'G', 'J/K', 'J/K', 'I/J', 'I'],
                [[13, 14], 'K/L', 'J', 'GG', 'K/L', 'K/L', 'J/K', 'J'],
                [[14, 15], 'M', 'K', 'H', 'M', 'M', 'L', 'K'],
                [[15, 16], 'N', 'L', 'HH', 'N', 'N', 'M', 'L'],
                [[16, 17], 'O/P', 'M', 'J', 'O/P', 'O/P', 'N/O', 'M'],
                [[17, 18], 'Q', 'N', 'JJ', 'R', 'R', 'P', 'N'],
                [[18, 19], 'R', 'O', 'K', 'S', 'S', 'R', 'O'],
                [[19, 20], 'S', 'P', 'KK', 'T', 'T', 'S', 'P'],
                [[20, 21], '—', '—', 'L', '—', '—', '—', '—'],
                [[21, 22], '—', '—', 'LL', '—', '—', '—', '—'],
                [[22, 23], '—', '—', 'M', '—', '—', '—', '—'],
                [[23, 24], '—', '—', 'MM', '—', '—', '—', '—'],
                [[24, 25], '—', '—', 'N', '—', '—', '—', '—'],
            ]
        };
        this.braTable = {
            metric: [
                [[53, 57], '55', '26', '26', '70', '—', '4', '55'],
                [[58, 62], '60', '28', '28', '75', '0', '6', '60'],
                [[63, 67], '65', '30', '30', '80', '1', '8', '65'],
                [[68, 72], '70', '32', '32', '85', '2', '10', '70'],
                [[73, 77], '75', '34', '34', '90', '3', '12', '75'],
                [[78, 82], '80', '36', '36', '95', '4', '14', '80'],
                [[83, 87], '85', '38', '38', '100', '5', '16', '85'],
                [[88, 92], '90', '40', '40', '105', '6', '18', '90'],
                [[93, 97], '95', '42', '42', '110', '7', '20', '95'],
                [[98, 102], '100', '44', '44', '115', '8', '22', '100'],
                [[103, 107], '105', '46', '46', '120', '9', '24', '105'],
                [[108, 112], '110', '48', '48', '125', '10', '26', '110'],
                [[113, 117], '115', '50', '50', '130', '11', '28', '115'],
                [[118, 122], '120', '52', '52', '135', '12', '30', '120'],
                [[123, 127], '125', '54', '54', '140', '13', '32', '125'],
                [[128, 132], '130', '56', '56', '145', '14', '34', '130'],
                [[133, 137], '135', '58', '58', '150', '15', '36', '135'],
                [[138, 142], '140', '60', '60', '155', '16', '38', '140'],
                [[143, 147], '145', '62', '62', '160', '17', '40', '145'],
                [[148, 152], '150', '64', '64', '165', '18', '42', '150'],
                [[153, 157], '155', '66', '66', '170', '19', '44', '155'],
            ],
            imperial: [
                [[22, 23], '55', '26', '26', '70', '—', '4', '55'],
                [[24, 25], '60', '28', '28', '75', '0', '6', '60'],
                [[26, 27], '65', '30', '30', '80', '1', '8', '65'],
                [[28, 29], '70', '32', '32', '85', '2', '10', '70'],
                [[30, 31], '75', '34', '34', '90', '3', '12', '75'],
                [[32, 33], '80', '36', '36', '95', '4', '14', '80'],
                [[34, 35], '85', '38', '38', '100', '5', '16', '85'],
                [[36, 37], '90', '40', '40', '105', '6', '18', '90'],
                [[38, 39], '95', '42', '42', '110', '7', '20', '95'],
                [[40, 41], '100', '44', '44', '115', '8', '22', '100'],
                [[42, 43], '105', '46', '46', '120', '9', '24', '105'],
                [[44, 45], '110', '48', '48', '125', '10', '26', '110'],
                [[46, 47], '115', '50', '50', '130', '11', '28', '115'],
                [[48, 49], '120', '52', '52', '135', '12', '30', '120'],
                [[50, 51], '125', '54', '54', '140', '13', '32', '125'],
                [[52, 53], '130', '56', '56', '145', '14', '34', '130'],
                [[54, 55], '135', '58', '58', '150', '15', '36', '135'],
                [[56, 57], '140', '60', '60', '155', '16', '38', '140'],
                [[58, 59], '145', '62', '62', '160', '17', '40', '145'],
                [[60, 61], '150', '64', '64', '165', '18', '42', '150'],
                [[62, 63], '155', '66', '66', '170', '19', '44', '155'],
            ],
        };
    }
    $onInit() {
    }
    setUnit(unit) {
        this.unit = unit;
        this.clear(this.braForm);
    }
    submitForm($form) {
        this.result = undefined;
        this.hasError = false;
        if ($form.$valid && (this.data.band != undefined) && (this.data.bust != undefined)) {
            let band = this.data.band;
            let bust = this.data.bust;
            // if (this.unit == 'imperial') {
            //     band *= 2.54
            //     band *= 2.54
            // }
            const diff = bust - band;
            if (diff > 0) {
                this.result = [];
                try {
                    this.tableHeads.forEach((head) => {
                        let addition = 0;
                        if (addition && (this.unit == 'imperial'))
                            addition = addition / 2.54;
                        const matchBraRows = this.braTable[this.unit].filter((item) => {
                            const range = item[0];
                            return (range[0] <= band) && (band <= range[1]);
                        });
                        if (matchBraRows.length == 0) {
                            throw "Band size is out of any rage";
                        }
                        const matchCupRows = this.cupTable[this.unit].filter((item) => {
                            const range = item[0];
                            return (range[0] <= (diff - addition)) && ((diff - addition) <= range[1]);
                        });
                        this.result.push({
                            head: head,
                            diff: diff - addition,
                            matchBraValues: matchBraRows.map((row) => row[head.index]),
                            matchCupValues: matchCupRows.map((row) => row[head.index]),
                        });
                    });
                }
                catch (_a) {
                    this.hasError = true;
                    this.result = undefined;
                }
            }
            else {
                this.hasError = true;
                this.result = undefined;
            }
        }
    }
    clear($form) {
        if ($form) {
            $form.$setPristine();
            $form.$setUntouched();
        }
        this.hasError = false;
        this.result = undefined;
        this.data = {};
    }
}
BrasizecalculatorCtrl.$inject = ['$scope', '$timeout', 'ModalServiceFactory', 'ConfigService', '$filter'];
const appModule = ng.module('app');
// appModule.filter('toTitle', () => {
//     return (items: any[], sep = ', ') => {
//         return items.map((item: any) => `${item.name} (${item.code})`).join(sep)
//     }
// })
appModule.filter('toTitle', ($filter) => {
    return (items, sep = ', ') => {
        return items
            .map((item) => `${item.name}${$filter('translate')('(')}${item.code}${$filter('translate')(')')}`).join(sep);
    };
});
appModule.component('gameBrasizecalculator', {
    transclude: true,
    template: require("./game.ng.html"),
    controller: BrasizecalculatorCtrl,
    controllerAs: '$ctrl',
    bindings: {
        config: "<"
    }
});
appModule.config(['WsServiceProvider', (WsServiceProvider) => {
        WsServiceProvider.setPrefix('brasizecalculator/');
    }]);
